import { useEffect, useState, ReactNode } from "react";
import DropdownJSON from "data/locales/id/dropdown.json";

///ICON
import { IconLock } from "components/Icon/IconLock";
import { IconStatus } from "components/Icon/IconStatus";
import IconUser from "components/Icon/IconUser";
import IconSignOutAlt from "components/Icon/IconSignOutAlt";
import { useAuthLogoutApi } from "modules/Auth/authApi";
import { useRouter } from "next/router";
import { useAuthStore } from "modules/Auth/authStore";
import IconApperance from "components/Icon/IconApperance";
import ToggleDarkMode from "components/Form/ToggleDarkMode";
import Link from "next/link";
///ICON

export const Dropdown: React.FC<{
  id?: string;
  theme?: string;
  children?: ReactNode;
  className?: string;
  included?: boolean;
}> = ({ id = "", theme = "", children, className, included }) => {
  const [dataDropdown, setDataDropdown] = useState([]);

  useEffect(() => {
    if (id) {
      setDataDropdown(DropdownJSON[id]);
    }
  }, [id]);

  const renderIcon = (icon: string) => {
    switch (icon) {
      case "user":
        return <IconUser className="fill-purple-80" />;
      case "status":
        return <IconStatus className="fill-purple-80" />;

      case "lock":
        return <IconLock className="fill-purple-80" />;

      case "sign-out":
        return <IconSignOutAlt className="fill-purple-80" />;

      case "apperance":
        return <IconApperance className="fill-purple-80" />;
      default:
        return <IconUser className="fill-purple-80" />;
    }
  };

  const authLogoutApi = useAuthLogoutApi();
  const router = useRouter();
  const authStore = useAuthStore();

  return (
    <div
      className={
        `absolute right-[-10px] top-30 z-10 mt-2 w-fit origin-top-right rounded-md bg-white_to_dark20 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ` +
        theme
      }
      role="dropdown"
      aria-orientation="vertical"
      aria-labelledby={id}
      tabIndex={-1}
    >
      {id && (
        <div className="py-1 min-w-[250px]" role="none">
          {dataDropdown &&
            dataDropdown.length > 0 &&
            dataDropdown.map((a, index) => (
              <div
                className="flex justify-between items-center"
                key={id + "-" + index}
              >
                <Link
                  legacyBehavior
                  href={a.href || "#"} // Ganti a.href dengan path yang sesuai
                  passHref
                >
                  <a
                    className="cursor-pointer block py-16 text-b1 text-purple-80 font-light hover:font-medium flex items-center gap-8"
                    role="dropdownitem"
                    id={id + "-" + index}
                  >
                    {a.icon && <div>{renderIcon(a.icon)}</div>}
                    {a.label}
                  </a>
                </Link>

                {a.id == 4 && <ToggleDarkMode isSwitch={true} />}
              </div>
            ))}
        </div>
      )}

      {children && <div className={className}>{children}</div>}
    </div>
  );
};
